// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Trash,Edit} from 'iconsax-react';

// icons
const icons = {
  edit:Edit,
  delete:Trash,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: 'historique',
  title: <FormattedMessage id="historique" />,
  type: 'group',
  children: [
    {
      id: 'edit',
      title: <FormattedMessage id="edit" />,
      type: 'item',
      url: '/historique-modifications',
      icon: icons.edit
    },
    {
      id: 'delete',
      title: <FormattedMessage id="delete" />,
      type: 'item',
      url: '/historique-suppressions',
      icon: icons.delete
    }
  ]
};

export default support;
