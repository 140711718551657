// project-imports
import menu_admin from './menu';
import utilisateur from './user';
import historique from './historique';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [menu_admin,utilisateur,historique]
};

export default menuItems;
