// material-ui
import { Box,  } from '@mui/material';
import useAuth from 'hooks/useAuth';


// assets

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => {
  const { user } = useAuth();

  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 2 } }}>
      {user?.hotel !== null ? (
        <h3 style={{ color: 'black' }}>{user?.hotel.nom}</h3>
      ) : null}
    </Box>
  );
};

export default Search;
