import { useState } from 'react';
import axios from 'utils/axios';
import { PopupTransition } from 'components/@extended/Transitions';
import { FormattedMessage } from 'react-intl';
import showSnackBar from 'utils/functions/showSnackBar';

import {
  Grid,
  InputLabel,
  TextField,
  Stack,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  InputAdornment
} from '@mui/material';
import IconButton from 'components/@extended/IconButton';

import { Eye, EyeSlash } from 'iconsax-react';


import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import useAuth from 'hooks/useAuth';

function ChangePassword({ open, handleClickOpenChange }) {

  const { user } = useAuth();
  const [showPassword0, setShowPassword0] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(false); // État du chargement
  const handleClose = () => {
    handleClickOpenChange(!open);
  };
  const handleShowPassword0 = () => {
    setShowPassword0(!showPassword0);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('L\'ancien mot de passe est requis'),
    new_password: Yup.string().required('Le mot de passe est requis'),
    passwordconf: Yup.string()
      .required('La confirmation du mot de passe est requise')
      .oneOf([Yup.ref('new_password')], 'Les mots de passe ne sont pas identiques')
  });

  const handleSubmit = async (objectUser, { resetForm }) => {
    setLoading(true); // Activer le chargement
    try {
      // Effectuer la requête POST vers l'API
        const reinit=await axios.put(`user/update-password/${user.id}`, objectUser);
        resetForm();
        if(reinit.status==200){
            if(reinit.data.answer=='SUCCESS'){
                showSnackBar({ message: 'Changement du mot de passe effectué avec succès', color: 'success' });
                handleClose(); // Fermer le dialogue après la réussite de la requête
              }else{
                showSnackBar({ message: reinit.data.message, color: 'error' });
              }
        }else{
            showSnackBar({ message: 'Erreur lors du changement du mot de passe', color: 'error' });
        }

    } catch (error) {
      console.error("Erreur lors du changement du mot de passe:", error);
      showSnackBar({ message: 'Erreur lors du changement du mot de passe', color: 'error' });
    }
    setLoading(false); // Désactiver le chargement
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={PopupTransition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xs"
    >
      <Formik  onSubmit={handleSubmit} initialValues={{old_password:'',new_password:'',passwordconf:''}} validationSchema={validationSchema}>
        <Form>
          <Box sx={{ p: 1, py: 1.5 }}>
            <DialogTitle><FormattedMessage id="changepass" /></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="old_password"><FormattedMessage id="oldpass" /></InputLabel>
                      <Field
                        as={TextField}
                        fullWidth
                        id="old_password"
                        type={showPassword0 ? 'text' : 'password'}
                        name="old_password"
                        placeholder="Ancien mot de passe"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword0} edge="end">
                                {showPassword0 ? <EyeSlash /> : <Eye />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Stack>
                    <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name="new_password" component="div" className="error-message" />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="new_password"><FormattedMessage id="newpass" /></InputLabel>
                      <Field
                        as={TextField}
                        fullWidth
                        id="new_password"
                        type={showPassword ? 'text' : 'password'}
                        name="new_password"
                        placeholder="Mot de passe"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                {showPassword ? <EyeSlash /> : <Eye />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Stack>
                    <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name="new_password" component="div" className="error-message" />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="passwordconf"><FormattedMessage id="passwordconf" /></InputLabel>
                      <Field
                        as={TextField}
                        fullWidth
                        id="passwordconf"
                        type={showPassword2 ? 'text' : 'password'}
                        name="passwordconf"
                        placeholder="Confirmation mot de passe"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword2} edge="end">
                                {showPassword2 ? <EyeSlash /> : <Eye />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Stack>
                    <ErrorMessage
                      style={{ color: 'red', fontSize: '12px' }}
                      name="passwordconf"
                      component="div"
                      className="error-message"
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={handleClose}>
              <FormattedMessage id="cancel" />
              </Button>
              <Button variant="contained" type="submit" disabled={loading}>
              {loading ? (
                  <CircularProgress size={24} /> // Afficher le loader si la requête est en cours
                ) : <FormattedMessage id="submit" />}
              </Button>
            </DialogActions>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
}
export default ChangePassword;
