import { useState } from 'react';
import axios from 'utils/axios';
import { PopupTransition } from 'components/@extended/Transitions';
import { FormattedMessage } from 'react-intl';
import showSnackBar from 'utils/functions/showSnackBar';
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'config';


import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Stack,
  Typography,FormLabel, TextField,
} from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import { Camera } from 'iconsax-react';

import { Formik, Form } from 'formik';
import useAuth from 'hooks/useAuth';

function ChangeProfil({ open, handleClickOpenChange }) {
  const theme = useTheme();

  const { user } = useAuth();
  const [loading, setLoading] = useState(false); // État du chargement
  const [base64E, setBase64E] = useState(true); // État du chargement
  const [image, setImage] = useState(user?.photo); // État du chargement
  const [imageUrl, setImageUrl] = useState(''); // État du chargement
  const handleClose = () => {
    handleClickOpenChange(!open);
  };
  const getImageType = (base64) => {
    try {
      const startIndex = base64.indexOf('/') + 1;
      const endIndex = base64.indexOf(';');
      return base64.substring(startIndex, endIndex);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true); // Activer le chargement
    try {
      console.log({ image: image });
      // Effectuer la requête POST vers l'API pour envoyer la nouvelle photo (base64Image)
      const response = await axios.put(`user/update-photo/${user.id}`, { image: image });

      if (response.data.answer === 'SUCCESS') {
        showSnackBar({ message: 'Changement de la photo de profil effectué avec succès', color: 'success' });
        handleClose(); // Fermer le dialogue après la réussite de la requête
        window.location.reload(); // Recharger la page
      } else {
        showSnackBar({ message: response.data.message, color: 'error' });
      }
    } catch (error) {
      console.error('Erreur lors du changement de la photo de profil:', error);
      showSnackBar({ message: 'Erreur lors du changement de la photo de profil', color: 'error' });
    } finally {
      setLoading(false); // Désactiver le chargement
    }
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleChangePicture = async (file) => {
    try {
      const imageUrl = URL.createObjectURL(file);
      const base64Image = await convertToBase64(file);
      setBase64E(false);
      setImage(base64Image);
      setImageUrl(imageUrl);
    } catch (error) {
      console.error('Erreur lors de la conversion en base64 :', error);
      // Gérer l'erreur si la conversion échoue
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={PopupTransition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xs"
    >
      <Formik onSubmit={handleSubmit} initialValues={{ id: '' }}>
        <Form>
          <Box sx={{ p: 1, py: 1.5 }}>
            <DialogTitle style={{ textAlign: 'center' }}>
              <FormattedMessage id="changeprof" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Grid container spacing={1}>
                  <Grid container justify="center">
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                      <Stack spacing={2.5} alignItems="center">
                        <FormLabel
                          htmlFor="change-avtar"
                          sx={{
                            position: 'relative',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            '&:hover .MuiBox-root': { opacity: 1 },
                            cursor: 'pointer',
                            marginTop:1,
                            width: 250, height: 250
                          }}
                        >
                          <Avatar alt="Avatar 1" src={base64E ? `data:image/${getImageType(user?.photo)};base64, ${user?.photo}` : imageUrl} sx={{ width: 250, height: 250,marginTop:1, border: '1px dashed' }} />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                              width: '100%', 
                              height: '100%',
                              opacity: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Stack spacing={0.5} alignItems="center">
                              <Camera style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
                              <Typography sx={{ color: 'secondary.lighter' }}>Téléverser</Typography>
                            </Stack>
                          </Box>
                        </FormLabel>
                        <TextField
                          type="file"
                          id="change-avtar"
                          placeholder="Outlined"
                          variant="outlined"
                          sx={{ display: 'none' }}
                          onChange={(e) => handleChangePicture(e.target.files[0])}
                        />
                        <Stack spacing={0.5} alignItems="center">
                          <Typography variant="h5">{user?.fullname}</Typography>
                          <Typography color="secondary"> {user?.role=='ADMIN'?'Administrateur Général':user?.role=='GERANT'?'Administrateur d\'hôtel':'Réceptioniste'}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={handleClose}>
                <FormattedMessage id="cancel" />
              </Button>
              <Button variant="contained" type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress size={24} /> // Afficher le loader si la requête est en cours
                ) : (
                  <FormattedMessage id="submit" />
                )}
              </Button>
            </DialogActions>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
}

export default ChangeProfil;
