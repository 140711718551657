import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
import useAuth from 'hooks/useAuth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children}) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      const redirectTo =
        user.role == 'GERANT'
          ? '/dashboard-gerant'
          : user.role == 'SIMPLE'
          ? '/dashboard-gerant'
          : user.role == 'ADMIN'
          ? '/hotels'
          : '/dashboard-ciel-bleu';
      navigate(location?.state?.from ? location?.state?.from : redirectTo, {
        state: {
          from: ''
        },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
