import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout/MainLayoutGerant';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Les composants des différentes pages
const DashBoardGerant = Loadable(lazy(() => import('pages/gerant_pages/dashboard')));
const Rapports = Loadable(lazy(() => import('pages/gerant_pages/rapport')));
const Hebergements = Loadable(lazy(() => import('pages/gerant_pages/hebergements')));
const Litigieux = Loadable(lazy(() => import('pages/gerant_pages/hebergementsP')));
const Reservations = Loadable(lazy(() => import('pages/gerant_pages/reservations')));
const Chambres = Loadable(lazy(() => import('pages/gerant_pages/chambres')));
const Clients = Loadable(lazy(() => import('pages/gerant_pages/clients')));
const Categories = Loadable(lazy(() => import('pages/gerant_pages/categorieschambres')));
const Devises = Loadable(lazy(() => import('pages/gerant_pages/devises')));
const AutresFinances = Loadable(lazy(() => import('pages/gerant_pages/autresfinances')));
const Utilisateurs = Loadable(lazy(() => import('pages/admin_pages/utilisateurs')));


// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard privilege='GERANT'>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard-gerant',
          element: <DashBoardGerant />
        },
        {
          path: 'rapport-gerant',
          element: <Rapports />
        },
        {
          path: 'hebergements',
          element: <Hebergements />
        },
        {
          path: 'autres-finances',
          element: <AutresFinances />
        },
        {
          path: 'hebergements-litigieux',
          element: <Litigieux />
        },
        {
          path: 'reservations',
          element: <Reservations />
        },
        {
          path: 'chambres',
          element: <Chambres />
        },
        {
          path: 'clients',
          element: <Clients />
        },
        {
          path: 'categories-chambres',
          element: <Categories />
        },
        {
          path: 'devises',
          element: <Devises />
        },
        {
          path: 'receptionistes',
          element: <Utilisateurs />
        }
      ]
    }
  ]
};

export default MainRoutes;
