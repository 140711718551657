import { useRoutes } from 'react-router-dom';

// project-imports

import LoginRoutes from './LoginRoutes';
import MainRoutesGerant from './routes_gerant/MainRoutesGerant';
import MainRoutesAdmin from './routes_admin/MainRoutesAdmin';
import MainRoutesCielBleu from './routes_cielbleu/MainRoutesCielBleu';
import MainRoutesSysteme from './routes_systeme/MainRoutesSysteme'

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, MainRoutesGerant,MainRoutesCielBleu,MainRoutesAdmin,MainRoutesSysteme]);
}
