// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { ProfileCircle } from 'iconsax-react';

// icons
const icons = {
  user:ProfileCircle,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: 'users',
  title: <FormattedMessage id="users" />,
  type: 'group',
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      url: '/utilisateurs',
      icon: icons.user
    }
  ]
};

export default support;
