import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ChangePassword from 'components/mycomponents/admin_components/changePassword';
import ChangeProfile from 'components/mycomponents/admin_components/changeProfil';

// assets
import { Logout, Lock, Profile } from 'iconsax-react';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const [openChange, setOpenChange] = useState(false);
  const [openChange2, setOpenChange2] = useState(false);

  const handleClickOpenChange = (newOpenValue) => {
    setOpenChange(newOpenValue);
  };
  const handleClickOpenChange2 = (newOpenValue) => {
    setOpenChange2(newOpenValue);
  };

  return (
    <>
      <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => {
            handleListItemClick(event, 0);
            handleClickOpenChange2(true);
          }}
        >
          <ListItemIcon>
            <Profile variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Changer la photo de profil" />
        </ListItemButton>
        
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => {
            handleListItemClick(event, 2);
            handleClickOpenChange(true);
          }}
        >
          <ListItemIcon>
            <Lock variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Changer de mot de passe" />
        </ListItemButton>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <Logout variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Se déconnecter" />
        </ListItemButton>
      </List>
      <ChangePassword open={openChange} handleClickOpenChange={handleClickOpenChange} />
      <ChangeProfile open={openChange2} handleClickOpenChange={handleClickOpenChange2} />
    </>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
