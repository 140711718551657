import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import otherAxios from 'axios';

const axiosQuery = otherAxios.create({ baseURL: 'https://api.cielbleu-app.com' });


// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};


// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken); 
          const response = await axios.get('/account/me');
          const { user } = response.data;
          console.log(user)
          if(user.role=='GERANT' || user.role=='SIMPLE'){
            user.hotel=user.hotels[0];
          }else{
            if(sessionStorage.getItem('hotel')===null){
              user.hotel=null
            }else{
              user.hotel = JSON.parse(sessionStorage.getItem('hotel'));
            }
          }
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);
  const changeJob = async (hotel) => {
    try {
      const serviceToken = localStorage.getItem('serviceToken');
      if (serviceToken && verifyToken(serviceToken)) {
        setSession(serviceToken); 
        const response = await axios.get('/account/me');
        const { user } = response.data;
        user.hotel=hotel
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user
          }
        });
      } else {
        dispatch({
          type: LOGOUT
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: LOGOUT
      });
    }
  };

  const login = async (email, password) => {
    const response = await axiosQuery.post('/api/login', { email, password });
    const { token, user } = response.data;
    if(user.role=='GERANT' || user.role=='SIMPLE'){
      user.hotel=user.hotels[0];
    }else{
      user.hotel=null
    }
    setSession(token);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (nom,prenom,nom_entreprise,siege,telephone,phone,email, password) => {
    // todo: this flow need to be recode as it not verified
    const object={nom,prenom,nom_entreprise,siege,telephone,phone,email, password}
    const response = await axiosQuery.post('/register', object);
    return response;
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email) => {
    const response = await axiosQuery.post('/api/register/forget-password', {email});
    return response;
  };

  const setNewPassword = async (token,password) => {
    const response = await axiosQuery.put('/api/register/change-password', {token,password});
    return response;
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile,setNewPassword,changeJob }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
