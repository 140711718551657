// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Moneys,Building,Story} from 'iconsax-react';

// icons
const icons = {
  money:Moneys,
  hotels:Building,
  historique:Story
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: 'menu',
  title: <FormattedMessage id="menu" />,
  type: 'group',
  children: [
    {
      id: 'hotels',
      title: <FormattedMessage id="hotels" />,
      type: 'item',
      url: '/hotels',
      icon: icons.hotels
    },
    {
      id: 'subscription',
      title: <FormattedMessage id="subscription" />,
      type: 'item',
      url: '/abonnements',
      icon: icons.money
    }
  ]
};

export default support;
