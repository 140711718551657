import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

const showSnackBar=({message,color,duration=2000})=> {
    dispatch(
        openSnackbar({
          open: true,
          message: message,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'alert',
          alert: {
            color: color
          },
          close: false,
        })
      );
      setTimeout(() => {
        dispatch(
          openSnackbar({
            open: false
          })
        );
      }, duration);
  }

  export default showSnackBar;

  