// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Profile2User,Building,Building3,Timer1,House,Warning2,Moneys} from 'iconsax-react';

// icons
const icons = {
  client:Profile2User,
  chambre: House,
  delai:Timer1,
  hebergement:Building,
  reservation: Building3,
  probleme: Warning2,
  money:Moneys,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: 'menu',
  title: <FormattedMessage id="menu" />,
  type: 'group',
  children: [
    {
      id: 'hebergement',
      title: <FormattedMessage id="accommodation" />,
      type: 'item',
      url: '/hebergements',
      icon: icons.hebergement
    },
    {
      id: 'mouvfin',
      title: <FormattedMessage id="mouvfin" />,
      type: 'item',
      url: '/autres-finances',
      icon: icons.money
    },
    {
      id: 'probleme',
      title: <FormattedMessage id="accommodationP" />,
      type: 'item',
      url: '/hebergements-litigieux',
      icon: icons.probleme
    },
    {
      id: 'reservation',
      title: 'Reservations',
      type: 'item',
      url: '/reservations',
      icon: icons.reservation
    },
    {
      id: 'room',
      title: <FormattedMessage id="room" />,
      type: 'item',
      url: '/chambres',
      icon: icons.chambre
    },
    {
      id: 'client',
      title: <FormattedMessage id="client" />,
      type: 'item',
      url: '/clients',
      icon: icons.client
    }
  ]
};

export default support;
