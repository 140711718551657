// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {ChartSquare,DocumentFilter } from 'iconsax-react';

// icons
const icons = {
  dash:ChartSquare,
  rep:DocumentFilter
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: 'dashboard',
  title: <FormattedMessage id="dashboard" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard-gerant',
      icon: icons.dash
    },
    {
      id: 'report',
      title: <FormattedMessage id="report" />,
      type: 'item',
      url: '/rapport-gerant',
      icon: icons.rep
    }
  ]
};

export default support;
