// project-imports
import dashboard_gerant from './dashboard';
import menu_gerant from './menu';
import parametre from './parameter';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard_gerant, menu_gerant,parametre]
};

export default menuItems;
