import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout/MainLayoutAdmin';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Les composants des différentes pages
const DashBoardAdmin = Loadable(lazy(() => import('pages/admin_pages/dashboard')));
const Rapports = Loadable(lazy(() => import('pages/admin_pages/rapport')));
const Hotels = Loadable(lazy(() => import('pages/admin_pages/hotels')));
const Abonnements = Loadable(lazy(() => import('pages/admin_pages/abonnement')));
const Historiquemod = Loadable(lazy(() => import('pages/admin_pages/historiquemod')));
const Historiquesup = Loadable(lazy(() => import('pages/admin_pages/historiquesup')));
const Utilisateurs = Loadable(lazy(() => import('pages/admin_pages/utilisateurs')));
const NotFoundPage = Loadable(lazy(() => import('pages/maintenance/error/404')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  children:  [
    {
      path: '/',
      element: (
        <AuthGuard privilege='ADMIN'>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard-admin',
          element: <DashBoardAdmin />
        },
        {
          path: 'rapport-admin',
          element: <Rapports />
        },
        {
          path: 'hotels',
          element: <Hotels />
        },
        {
          path: 'abonnements',
          element: <Abonnements />
        }, 
        {
          path: 'historique-modifications',
          element: <Historiquemod />
        },
        {
          path: 'historique-suppressions',
          element: <Historiquesup />
        },
        {
          path: 'utilisateurs',
          element: <Utilisateurs />
        }
      ]
    }
  ]
};

const NotFoundRoute = {
  path: '*',
  element: <NotFoundPage />
};

export default {
  ...MainRoutes,
  children: [...MainRoutes.children, NotFoundRoute]
};