// ...
import { useState } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

// assets
import { Profile } from 'iconsax-react';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = () => {
  const navigate = useNavigate();
  const { user, changeJob } = useAuth();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleHotelClick = async (event, index, id) => {
    handleListItemClick(event, index);
    user.hotel = user?.hotels.find((hotel) => hotel.id === id);

    // Convertir l'objet JSON en une chaîne JSON
    var hotelJSON = JSON.stringify(user.hotel);
    // Stocker la chaîne JSON dans sessionStorage
    sessionStorage.setItem('hotel', hotelJSON);

    navigate('/dashboard-gerant', { replace: true });
    await changeJob(user.hotel);
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {user?.role === 'ADMIN' && (
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => {
            handleListItemClick(event, 0);
            user.hotel = null;
            sessionStorage.removeItem('hotel');
            navigate('/hotels', { replace: true });
          }}
        >
          <ListItemIcon>
            <Profile variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Administrateur général" />
        </ListItemButton>
      )}
      {user?.hotels.map((hot, index) => (
        <ListItemButton
          key={index + 1}
          selected={selectedIndex === index + 1}
          onClick={(event) => {
            handleHotelClick(event, index + 1, hot.id);
            handleListItemClick(event, index + 1);
          }}
        >
          <ListItemIcon>
            <Profile variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary={`Administrateur ${hot.nom}`} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default SettingTab;
